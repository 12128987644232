<template>
  <div class="home">
    <!-- <div class="top-header">
    </div> -->

    <div class="page-content">
      <pageTitle :appicon="appConf.appicon" :appName="appConf.appName"></pageTitle>
      <pageDesc></pageDesc>

      <div class="page-btn">
        <div v-if="!playBtnShow" style="
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
          ">
          <div v-if="insertLoading" class="wati_box">
            <div class="progress-container">
              <div class="progress-bar" :style="{ width: progress + '%' }"></div>
              <div class="progress-text">
                Instalando
                {{ progress.toFixed(2) }}%
              </div>
            </div>
          </div>
          <div v-else @click="showDialog" style="width: 100%; height: 100%">
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 11px;
                margin-bottom: 3px;
                color: rgb(255, 227, 54);
              ">
              <img src="./assets/ss.svg" style="margin-right: 1px; width: 21px; height: 21px" />
              <span class="sd">Rapid Instalar</span>
            </div>
            <span style="font-size: 12px">Baixe dentro de 15 segundos</span>
          </div>
        </div>

        <div class="play_game_box" v-if="playBtnShow" @click="playGame()">
          <span>Iniciar jogo</span>
        </div>
      </div>

      <pageShare @func="showDialog"></pageShare>

      <div class="page-imgs">
        <div v-for="(item, key) in imgArr" class="img-item" :key="key">
          <img :src="item.src" alt="" @click="showDialog" />
        </div>
      </div>

      <pageInfo @func="showDialog"></pageInfo>

      <div class="page-news">
        <h2>O que há de novo</h2>
        <div class="page-news-conetent">
          Olá, fãs de Fortune Tiger Slot!
          <br />
          Aqui está uma nova atualização:
          <br />
          <br />
          -Melhorias de desempenho
          <br />
          <br />
          Aproveite e divirta-se!
        </div>
      </div>
      <pageGame @func="showDialog"></pageGame>
      <pageMessage @func="showDialog"></pageMessage>
      <pageOtherMessage @func="showDialog"></pageOtherMessage>
    </div>
    <bottomFooter @func="showDialog"></bottomFooter>

    <div class="dialog" v-show="show">
      <div class="dialog-mask"></div>
      <div class="dialog-content">
        <div class="dialog-content-title">
          <img src="./assets/title-logo.png" alt="" />
          <span>Rapid Instalar</span>
        </div>
        <div class="is_loading" v-show="showLoading">
          <div class="install-now__loading">
            <div class="loading-ci-box"></div>
            <span>{{ initNum }}%</span>
          </div>
        </div>
        <div class="is_active" v-show="!showLoading">
          <span class="tag">
            <img src="./assets/ic_actived.png" alt="" />
            <span>Effective</span>
          </span>
          <div class="download-btn" @click="downloadApp">Install Now</div>
        </div>
        <div class="btn_speed_up" v-if="initNum < 98">Clique para acelerar</div>
      </div>
    </div>
    <div class="download-guide" v-show="showDownloadGuide">
      <div class="dialog-mask"></div>
      <img src="@/assets/guide.jpg" class="download-guide-img" alt="">
    </div>
    <div class="download-ios-guide" v-show="showIOSDownloadGuide">
      <div class="dialog-mask"></div>
      <div class="download-ios-box">
        <img src="@/assets/guide-ios.jpg" class="download-ios-guide-img" alt="">
        <p class="dialog-ios-guide-desc">
          Clique em "Adicionar à tela inicial" para salvar a tela inicial do iPhone
        </p>
      </div>
    </div>
    <div class="android-dialog" v-show="androidDialogShow">
      <div class="dialog-mask"></div>
      <div class="android-dialog-box">
        <p class="android-dialog-title">
          Lembrete de segurança
        </p>
        <p class="android-dialog-desc">
          Por favor, use o Google Chrome para
          abrir a instalação
        </p>
        <div class="box-btn">
          <span class="confirm-btn" @click="downloadChrome">Baixa<br />
            Google<br />
            Chrome
          </span>
          <span class="redirect-btn" @click="openChromeCurrentPage">Instale<br />
            agora<br />
            rapidamente</span>
          <!-- <span v-else class="redirect-btn" @click="openBrowser">
            Jogar<br />
            Agora <br />
            Rapido</span> -->
        </div>
      </div>
    </div>
    <pwaJump></pwaJump>
  </div>
</template>
<script setup>
import bottomFooter from "./components/bottom-footer";
import pageTitle from "./components/page-title";
import pageDesc from "./components/page-desc";
import pageShare from "./components/page-share";
import pageOtherMessage from "./components/page-other-message";
import pageMessage from "./components/page-message";
import pageGame from "./components/page-game";
import pageInfo from "./components/page-info";
import pwaJump from "./components/pwaJump";
import { onMounted, ref, reactive } from "vue";
let startUrl = ``;
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
// 获取链接参数
if (urlParams.toString()) {
  startUrl = `${"?" + urlParams.toString()}`;
  localStorage.setItem("startUrl", startUrl);
} else if (localStorage.getItem("startUrl")) {
  startUrl = localStorage.getItem("startUrl");
}
// 拼接参数
let url = "https://" + window.location.host + startUrl;

const imgArr = ref([]);
const insertLoading = ref(false);
const show = ref(false);
const isInit = ref(false);
const showLoading = ref(true);
const playBtnShow = ref(false);
const showDownloadGuide = ref(false);
const showIOSDownloadGuide = ref(false);
const androidDialogShow = ref(false);
const appConf = reactive({
  appicon: "",
  appName: "",
});
let inInsert = ref(false)
// const
const initNum = ref(0);
const showDialog = () => {
  console.log('123123123=>', 123)
  if (inInsert.value == true) {
    return
  }
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPhone|iPad|iPod/.test(ua)) {
    playGame()
    return
  }

  const result = installClick();
  console.log('result=>', result)
  if (result) {
    return
  }
  if (!isInit.value) {
    const t = setInterval(() => {
      initNum.value++;
      if (initNum.value >= 100 || localStorage.getItem("pwaInstalled")) {
        initNum.value = 100;
        clearInterval(t);
        isInit.value = true;
        showLoading.value = false;
      }
    }, 200);
  }
  show.value = !show.value;
  if (localStorage.getItem("pwaInstalled")) {
    playGame();
  }
};
let downloadApp = () => {
  playGame();
};
let deferredPrompt;

window.addEventListener("beforeinstallprompt", (e) => {
  initNum.value = 98;
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;

  downloadApp = () => {
    // 安装pwa
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
        insertLoading.value = true;
        insertProLoad();
      } else {
        console.log("User dismissed the install prompt");
      }
      deferredPrompt = null;
    });
    showDialog();
  };
});
// })
const playGame = () => {
  localStorage.setItem("pwaInstalled", "true"); // 可以将安装状态保存下来
  // 用户已安装 PWA，执行唤醒逻辑
  window.open(url, "_blank");
};

// 安装进度条
const progress = ref(0);
const insertProLoad = () => {
  inInsert.value = true
  const interval = setInterval(() => {
    if (progress.value < 100) {
      progress.value += 100 / 16; // 每秒增加约 8.33%
      console.log('progress.value=>', progress.value)
      if (progress.value >= 100) {
        progress.value = 100;
        playBtnShow.value = true;
      }
    } else {
      insertLoading.value = false;
      clearInterval(interval);
    }
  }, 1000); // 每秒更新
};
window.addEventListener("DOMContentLoaded", async (event) => {
  try {
    if (typeof da !== "undefined") {
      da.init();
    }
  } catch (error) {
    console.error("Error initializing da:", error);
  }
  // 获取渠道id
  let cid = getQueryString("c") || 1;
  if (cid) {
    localStorage.setItem("c", cid);
  }
  let manifestUrl = `${process.env.VUE_APP_RES_URL}pwa_conf/${cid}/manifest.json`;
  fetch(manifestUrl, { method: "HEAD" }) // 使用 'HEAD' 请求只获取响应头，效率更高
    .then(async (response) => {
      if (response.ok) {
        // 资源存在
        await initManifest(manifestUrl);
      } else {
        manifestUrl = `${process.env.VUE_APP_RES_URL
          }pwa_conf/${1}/manifest.json`;
        await initManifest(manifestUrl);
      }
    })
    .catch(async (error) => {
      manifestUrl = `${process.env.VUE_APP_RES_URL}pwa_conf/${1}/manifest.json`;
      await initManifest(manifestUrl);
    });

  // Fetch manifest.json content
  // 查找 <link rel="manifest"> 元素
  const manifestLink = document.querySelector('link[rel="manifest"]');

  // 监听加载错误
  if (manifestLink) {
    manifestLink.addEventListener("error", async () => {
      manifestUrl = `${process.env.VUE_APP_RES_URL}pwa_conf/${1}/manifest.json`;
      initManifest(manifestUrl);

      console.error("Failed to load manifest.json");
      // 在这里处理加载失败的逻辑
    });
  }
});
// 插入meta
const addMetaTag = (name, content) => {
  let meta = document.querySelector(`meta[name="${name}"]`);
  if (meta) {
    meta.name = name;
    meta.content = content;
    console.log('meta=>', meta)
  } else {
    meta = document.createElement('meta');
    meta.name = name;
    meta.content = content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
}
// 插入link
const addLinkTag = (rel, href, sizes) => {
  const link = document.createElement('link');
  link.rel = rel;
  link.href = href;
  link.sizes = sizes;
  document.head.appendChild(link);
}
const initManifest = async (manifestUrl) => {
  try {
    // Create a new <link> element for the manifest
    // const manifestLink = document.createElement("link");
    // manifestLink.rel = "manifest";

    // manifestLink.href = manifestUrl;

    // // Remove any existing manifest link
    // const existingManifestLink = document.querySelector('link[rel="manifest"]');
    // if (existingManifestLink) {
    //   existingManifestLink.remove();
    // }

    // // Append the new <link> element to the <head>
    // document.head.appendChild(manifestLink);

    try {
      const response = await fetch(manifestUrl);
      if (response.ok) {
        // 动态配置页面
        const manifestData = await response.json();
        // appicon
        appConf.appicon = manifestData.icons[0].src;

        console.log(manifestData);
        // 修改浏览器icon图标
        const linkTags = document.querySelectorAll("link[rel='icon']");
        linkTags.forEach((link) => {
          link.href = appConf.appicon;
        });
        appConf.appName = manifestData.name;
        document.title = manifestData.name;

        // 特殊处理IOS 动态插入meta 安装名
        if (manifestData.name) {
          addMetaTag('apple-mobile-web-app-title', manifestData.name)
        }
        // 插入link标签
        const item = manifestData?.icons?.[0]
        if (item) {
          let link = document.querySelector(`link[rel="apple-touch-icon"]`);
          if (link) {
            link.href = item.src;
            link.sizes = "512x512"
          } else {
            addLinkTag("apple-touch-icon", item.src, "512x512")
          }
        }
        if (manifestData.screenshots.length > 0) {
          for (let i = 0; i < manifestData.screenshots.length; i++) {
            const element = manifestData.screenshots[i];
            imgArr.value.push({ src: element.src });
          }
        }
        manifestData.start_url = url;
        manifestData.related_applications[0].url = url;
        // 生成动态 manifest blob
        const stringManifest = JSON.stringify(manifestData);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);

        // 查找并替换页面中的 manifest link 标签
        let manifestElement = document.querySelector('link[rel="manifest"]');

        if (manifestElement) {
          manifestElement.href = manifestURL;
        } else {
          // 如果没有找到现有的 manifest 标签，就创建一个新的
          manifestElement = document.createElement("link");
          manifestElement.rel = "manifest";
          manifestElement.href = manifestURL;
          document.head.appendChild(manifestElement);
        }

        // 在这里你可以使用 manifestData 进行其他操作
        initDialogForUa(manifestData);
      } else {
        console.error("Failed to load manifest.json: ", response.status);
      }
    } catch (error) {
      console.error("Error fetching manifest.json:", error);
    }
  } catch (error) {
    console.log(error);
  }
};

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
// 初始化弹窗, 判断用户手机， 是否需要弹出引导图
const initDialogForUa = (manifestData) => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const currentUrl = window.location.href;
  const strippedUrl = currentUrl.replace(/^https?:\/\//, '');
  // ios手机
  if (/iPhone|iPad|iPod/.test(ua)) {
    // 如果在FB或者INS或者Kwai或者TT中打开 弹出引导图
    if (ua.includes('FB') || ua.includes('Instagram') || ua.includes('Kwai') || ua.includes('tiktok')) {
      // 
      showDownloadGuide.value = true;
    }
  } else if (/Android/.test(ua)) {
    // 安卓在FB或INS中打开
    if (ua.includes('FB') || ua.includes('Instagram')) {
      // 直接唤醒chrome跳转当前页
      openChromeCurrentPage();
      // 如果没有跳转走， 说明没有下载chrome， 弹出弹窗提示下载chrome
      // androidDialogShow.value = true;
    }
    // 如果在Kwai中打开
    else if (ua.includes('Kwai')) {
      showDownloadGuide.value = true;
    }
    else if (!ua.includes('Kwai') &&  (ua.includes('SM') ||  ua.includes('Browser') ||  ua.includes('Sam'))){
      androidDialogShow.value = true;
      openChromeCurrentPage()
    }
    // 判断是否在chrome中打开
    // else if(ua.includes("Chrome")) {
    //   console.log('123=>', ua)
    //   console.log('chrome内打开');
    // }

    // 如果在 手机本地浏览器打开，则弹出唤醒chrome弹窗
    else if (ua.includes("Browser") || ua.includes("SM") || ua.includes("Sam")) {
      console.log('123=>', ua)
      androidDialogShow.value = true;
    }
    // 如果在tg中打开
    else if (ua.includes('Telegram')) {
      showDownloadGuide.value = true;
    } else {
      installClick()
    }
  }
}
const installClick = () => {
  let isShowDialog = false
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPhone|iPad|iPod/.test(ua)) {
    if (ua.includes('FB') || ua.includes('Instagram') || ua.includes('Kwai') || ua.includes('tiktok')) {
      showDownloadGuide.value = true;
      isShowDialog = true
    } else {
      // showIOSDownloadGuide.value = true;
    }
  } else if (/Android/.test(ua)) {
    if (ua.includes('FB') || ua.includes('Instagram')) {
      androidDialogShow.value = true;
      isShowDialog = true
      openChromeCurrentPage()
    } else if (ua.includes('Kwai')) {
      showDownloadGuide.value = true;
      isShowDialog = true
    } 
    else if (!(window.matchMedia('(display-mode: standalone)').matches  || window.matchMedia('(display-mode: fullscreen)').matches) && !ua.includes('Kwai') &&  (ua.includes('SM') ||  ua.includes('Browser') ||  ua.includes('Sam'))){
      androidDialogShow.value = true;
      isShowDialog = true
      openChromeCurrentPage()
    }
    else if (ua.includes("Browser")) {
      console.log('123=>')
      androidDialogShow.value = true;
      isShowDialog = true
    } 
  }
  return isShowDialog
}
// 下载chrome
const downloadChrome = () => {
  window.location.href = 'https://play.google.com/store/apps/details?id=com.android.chrome';
}
// 唤醒chrome打开当前页
const openChromeCurrentPage = () => {
  const currentUrl = window.location.href;
  const strippedUrl = currentUrl.replace(/^https?:\/\//, '');
  window.location.href = 'intent://' + strippedUrl + '#Intent;package=com.android.chrome;scheme=http;end';
}
// 唤醒chrome打开当前页
const openBrowser = () => {
  const currentUrl = window.location.href;
  const strippedUrl = currentUrl.replace(/^https?:\/\//, '');
  // window.location.href = 'intent://' + strippedUrl + '#Intent;package=com.android.browser;scheme=http;end';
  window.location.href = url;
}
</script>

<style>
.download-guide {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.download-ios-guide {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.download-ios-box {
  width: 8rem;
  left: 1rem;
  position: fixed;
  z-index: 30001;
  top: 2rem;
}

.download-ios-guide-img {
  width: 100%;
}

.dialog-ios-guide-desc {
  padding-top: 1rem;
  color: #fff;
  font-size: 18px;
}

.download-guide-img {
  width: 100%;
  position: fixed;
  z-index: 30001;
}

.android-dialog {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.android-dialog-box {
  width: 8rem;
  height: 5.6rem;
  background-color: #fff;
  position: fixed;
  left: 1rem;
  top: calc(50% - 2rem);
  padding-top: 0.4rem;
  z-index: 30001;
  border-radius: .2rem;
  -webkit-animation: toggleScale 1.5s infinite both;
  animation: toggleScale 1.5s infinite both;
}

.android-dialog-title {
  font-size: 20px;
  padding: .2rem;
  padding-top: .4rem;
  text-align: center;
  font-weight: 700;
}

.android-dialog-desc {
  font-size: 16px;
  padding: .2rem;
  padding-top: .4rem;
  text-align: center;

}

.box-btn {
  width: 100%;
  padding: .4rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 0.5rem;
}

.confirm-btn {
  font-size: 14px;
  color: #fff;
  width: 2.8rem;
  background-color: rgb(1, 135, 95);
  border-radius: .1rem;
  text-align: center;
  padding: .2rem;
}

.redirect-btn {
  font-size: 14px;
  color: #fff;
  width: 2.8rem;

  background-color: #f3c738;
  border-radius: .1rem;
  text-align: center;
  padding: .2rem;
}

@-webkit-keyframes toggleScale {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }

  50% {
    -webkit-transform: scale(.97);
    -ms-transform: scale(.97);
    transform: scale(.97)
  }

  to {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes toggleScale {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }

  50% {
    -webkit-transform: scale(.97);
    -ms-transform: scale(.97);
    transform: scale(.97)
  }

  to {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }
}
</style>
